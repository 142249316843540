<template>
  <div class="table-colum-select" @click="focused = !focused" @blur="focused = false" tabindex="-1" ref="parent">
    <div class="table-colum-select__selected">
      {{selectedCount}}
    </div>
    <div class="table-colum-select__options" v-show="focused">
      <div class="table-colum-select__option" :class="{'table-colum-select__option--checked': option.show}" v-for="(option, i) in options" :key="i"
        @click.prevent="handleOption($event, option)">
        <div class="table-colum-select__checkbox"></div>
        <div class="table-colum-select__text">
          <span :title="option.headerTitle">{{option.label}}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      focused: false,
    }
  },
  computed: {
    selectedCount() {
      const total = this.options.length
      const selected = this.options.filter(o => o.show).length
      return `Showing ${selected} of ${total} columns`
    },
  },
  props: {
    storageKey: {
      type: String,
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    handleOption(event, option) {
      event.stopPropagation()
      option.show = !option.show
      if (option.show === true) {
        const newState = `${window.localStorage.getItem(this.storageKey)},${option.key}`
        window.localStorage.setItem(this.storageKey, newState)
      } else if (!option.show) {
        const newState = `${window.localStorage.getItem(this.storageKey).replace(`${option.key}`, '')}`
        window.localStorage.setItem(this.storageKey, newState)
      }
    },
  },
  created() {
    if (!window.localStorage.getItem(this.storageKey)) {
      const defaultFields = []
      this.options.forEach(el => {
        if (el.show) {
          defaultFields.push(el.key)
        }
      })
      window.localStorage.setItem(this.storageKey, defaultFields)
    } else {
      const selectedOptions = (window.localStorage.getItem(this.storageKey)).split(',')
      this.options.forEach(el => {
        selectedOptions.includes(el.key) ? el.show = true : el.show = false
      })
    }
  },
}
</script>
<style scoped>
.table-colum-select {
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  min-height: 30px;
  max-width: 190px;
  position: relative;
  display: inline-flex;
  flex-wrap: wrap;
  cursor: pointer;
  margin-left: 5px;
}

.table-colum-select:focus {
  outline: none;
}

.table-colum-select__selected {
  padding: 4px 8px;
  padding-right: 6px;
  margin: 3px 3px;
}

.table-colum-select__options {
  position: absolute;
  top: 34px;
  right: 0;
  left: 0;
  display: flex;
  background: #fff;
  flex-direction: column;
  box-shadow: 0 3px 3px 2px #e3e3e3;
  padding: 5px;
  min-height: 55px;
  max-height: 165px;
  overflow-y: auto;
  z-index: 9999;
}

.table-colum-select__option {
  /* padding: 6px 11px; */
  cursor: pointer;
  display: flex;
  align-items: center;
}

.table-colum-select__checkbox {
  width: 18px;
  height: 18px;
  border: 1px solid #969696;
  margin-right: 7px;
  position: relative;
}

.table-colum-select__option--checked .table-colum-select__checkbox {
  border: 1px solid #1f7bb8;
  background: #1f7bb8;
}

.table-colum-select__option--checked .table-colum-select__checkbox::after {
  width: 10px;
  height: 5px;
  border-left: 2px solid rgb(255, 255, 255);
  border-bottom: 2px solid rgb(255, 255, 255);
  content: "";
  z-index: 9999;
  position: absolute;
  transform: rotate(-45deg);
  left: 3px;
  top: 4px;
}
</style>
